<template>
  <div id="manage-coupon-list" class="fx-main container">
    <div class="full-width">
      <div
        v-for="coupon in couponList"
        :key="coupon.id"
        class="fx-main manage-coupon-item default-border  fx-a-i-cent mg-top-2 style-coupon"
        >

        <div class="manage-coupon-code">
          <span class="coupon-code">{{ coupon.code }}</span>
        </div>

        <div class="manage-coupon-uses">
          <span class="coupon-uses"> {{ coupon.current_uses || 0 }} de <template v-if="!coupon.max_uses || coupon.max_uses <= 0">Infinitos</template> <template v-else>{{ coupon.max_uses }}</template> usos </span>
        </div>

        <div class="edit-coupon">
          <!-- <i class="fas fa-ellipsis-h"></i> -->

          <div class="manage-coupon-menu-modal fx-center 1fx-wrap container-btn">
            <div v-on:click="showModal(coupon.id)" class="clickable btn style-button">
              <span>Editar</span>
            </div>
            <div v-on:click="deleteCoupon(coupon.id)" class="clickable">
              <i class="fa fa-trash clickable" aria-hidden="true"></i>
            </div>
          </div>

        </div>
      </div>
    </div>
    <modal
      name="coupon-manage-modal"
      :width="'85%'"
      :height="'80%'"
      :adaptive="true"
    >
      <div class="modal-header fx-main fx-end pd-15-px">
        <i class="clickable fa fa-times" aria-hidden="true" @click="hideModal"></i>
      </div>
      <div class="fx-main" v-if="couponModal">
        <div class="fx-main flex-md-fill">
          <div class="full-width">
            <div id="FormCoupon">
              <div class="input-group fx-main mg-bot-2em">
                <label class="margin-bottom" for="coupon_form_name">Código do Cupom</label>
                <input
                  class="default-border"
                  type="text"
                  id="coupon_form_name"
                  v-model="couponModal.code"
                />
              </div>
              <div class="input-group fx-main mg-bot-2em">
                <label class="margin-bottom" for="coupon_form_description">Descrição</label>
                <textarea
                  class="default-border"
                  type="text"
                  id="coupon_form_description"
                  v-model="couponModal.description"
                />
              </div>
              <div class="input-group fx-main mg-bot-2em">
                <label class="margin-bottom" for="coupon_form_max_uses">Usos Máximos</label>
                <textarea
                  class="default-border"
                  type="number"
                  id="coupon_form_max_uses"
                  v-model="couponModal.max_uses"
                />
              </div>

              <div class="input-group fx-main mg-bot-2em">
                <label class="margin-bottom" for="coupon_form_applies_for">Descontos Aplicáveis</label>
                <select
                  multiple="multiple"
                  class="border-radius-10 pd-0 scrollbar-none select-style"
                  id="coupon_form_applies_for"
                  v-model="couponModal.applies_for"
                >
                  <option value="ITEMS">Itens do Pedido</option>
                  <option value="SHIPPING">Frete</option>
                </select>
              </div>
              
              <span class="margin-bottom">Valor:</span>
              <money
                v-if="couponModal.modifier_type === 'PRICE'"
                class="default-border wd-100"
                type="text"
                v-model="couponModal.amount"
                v-bind="money"
              ></money>
              <the-mask
                v-if="couponModal.modifier_type === 'PERCENTAGE'"
                v-model="couponModal.amount"
                mask="##%"
                type="text"
                :masked="false"
                class="default-border wd-100"
              ></the-mask>
              <span class="margin-bottom pad-top wd-100">Tipo de Valor:</span>
              <div class="fx-main wd-100 margin-bottom pad-top">
                <label>Porcentagem</label>
                <input
                  type="radio"
                  name="coupon_mod"
                  v-model="couponModal.modifier_type"
                  value="PERCENTAGE"
                />
              </div>
              <div class="fx-main wd-100 margin-bottom">
                <label>Preço</label>
                <input
                  type="radio"
                  name="coupon_mod"
                  v-model="couponModal.modifier_type"
                  value="PRICE"
                />
              </div>

              <div class="input-group fx-main mg-bot-2em fx-a-i-cent margin-bottom">
            <span class="margin-bottom" for="coupon_form_published">Utilizável</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="coupon_form_published"
                v-model="couponModal.published"
                :true-value="true"
                :false-value="false"
              />
              <label for="coupon_form_published" class="switch__label"></label>
            </div>
          </div>

          <div class="input-group fx-main mg-bot-2em fx-a-i-cent margin-bottom">
            <span class="margin-bottom" for="coupon_form_cumulative">Cumulativo</span>
            <div class="switch">
              <input
                type="checkbox"
                class="switch__input"
                id="coupon_form_cumulative"
                v-model="couponModal.cumulative"
                :true-value="true"
                :false-value="false"
              />
              <label for="coupon_form_cumulative" class="switch__label"></label>
            </div>
          </div>

              <div class="input-group fx-main mg-bot-2em">
                <button class="btn clickable" v-on:click="updateCoupon(couponModal.id)">Alterar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped>
.style-coupon {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;

}

.style-button {
  margin-top: 0;
  width: 40%;
  max-width: 50%;
  font-size: .9em;
}

.container-btn {
  justify-content: space-around;
  
}

.full-width {
  width: 100%;
}

@media(max-width: 500px) {
  .style-button {
    width: 50%;
    font-size: .7em;
  }
}

.pd-0 {
  padding: 0 2px;
}
.border-radius-10 {
  border-radius: 10px;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0;
  visibility: hidden;
}

.select-style {
  height: 120px;
  font-weight: bold;
  font-size: .9em;
}

.select-style option {
  background-color: #eee !important;
  margin: 6px 0;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 3px;
}

.fx-main {
  width: 100%;
}

#FormCoupon {
    margin: 4%;
}

.margin-bottom {
  margin-bottom: 5px;
}

.pad-top{
  padding-top: 10px;
}

.default-border {
    padding: 1%;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .07);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .07);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 2px 2px rgba(0, 0, 0, .06), 0 0 2px rgba(0, 0, 0, .07);
}

</style>

<script>
import Coupon from "@/services/coupon.js";
import util from "@/services/util";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";

export default {
  name: "manage-coupon-list",
  data: function() {
    return {
      couponList: [],
      couponModal: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    };
  },
  mounted() {
    Coupon.list().then(coupons => {
      this.couponList = coupons;
    });
  },
  methods: {
    showModal: function(couponId) {
      Coupon.get(couponId).then(coupon => {
        if(coupon.modifier_type === 'PRICE') {
          coupon.amount = coupon.amount / 100;
        }
        if(!coupon.max_uses || coupon.max_uses <= 0) {
          coupon.max_uses = 0;
        }
        this.couponModal = coupon;
        this.$modal.show("coupon-manage-modal");
      });
    },
    hideModal: function() {
      this.$modal.hide("coupon-manage-modal");
    },
    updateCoupon: function() {
      var coupon = JSON.parse(JSON.stringify(this.couponModal));
      if(coupon.modifier_type === "PRICE") {
        coupon.amount = Number(String(coupon.amount)) * 100;
      }
      else {
        coupon.amount = Number(String(coupon.amount).replace(/\D/g, ""));
      }
      if(coupon.max_uses <= 0) {
        coupon.max_uses = null;
      }
      Coupon.edit(coupon).then(
        serviceReturn => {
          this.$notify({
            type: "success",
            title: "Cupom Atualizado",
            text: "Cupom Atualizado com Sucesso!"
          });
          this.couponList = this.couponList.map(p =>
            p.id == coupon.id ? coupon : p
          );
        },
        error => {
          this.hideModal();
          this.$notify({
            type: "error",
            duration : 24000,
            title: "Erro ao Editar Cupom",
            text: util.stringifyAxiosError(error)
          });
        }
      );
    },
    deleteCoupon: function(pid) {
      Coupon.delete(pid).then(
        response => {
          this.$notify({
            type: "success",
            title: "Cupom Excluido",
            text: "Cupom Excluido com Sucesso!"
          });
          this.couponList = this.couponList.filter(p => p.id != pid);
        },
        error => {
          this.$notify({
            type: "error",
            duration : 24000,
            title: "Erro ao Excluir Cupom",
            text: util.stringifyAxiosError(error)
          });
        }
      );
    }
  },
  components: {
    money: Money,
    "the-mask": TheMask
  }
};
</script>