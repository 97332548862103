import HeaderBuilder from '@/services/header-builder.js';
import axios from 'axios';
import {
    apiBaseUrl
} from "@/services/api.js";
import store from '@/store';
import Cart from "@/services/cart";

const Coupon = {
    list: async(searchOptions = {}) => {
        const params = new URLSearchParams(searchOptions);
        const response = await axios.get(`${apiBaseUrl}/api/coupon?${params.toString()}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    get: async(id, options = {}) => {
        const params = new URLSearchParams(options);
        const response = await axios.get(`${apiBaseUrl}/api/coupon/${id}?${params.toString()}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    create: async(payload) => {
        const response = await axios.post(`${apiBaseUrl}/api/coupon`, payload, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    edit: async(payload) => {
        const response = await axios.patch(`${apiBaseUrl}/api/coupon`, payload, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    delete: async(id) => {
        const response = await axios.delete(`${apiBaseUrl}/api/coupon/${id}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
    apply: async(id) => {
        const is_local_cart = !store.state.user.logged;
        const params = is_local_cart ? {
            cart: store.state.user.localCart
        } : '';
        const response = await axios.put(`${apiBaseUrl}/api/coupon/usage/${id}`, params, {
            headers: HeaderBuilder.build()
        });
        if (is_local_cart) {
            store.commit('setLocalCart', response.data);
            let new_cart = await Cart.get(true);
            return new_cart.coupons[new_cart.coupons.length - 1];
        }
        return response.data;
    },
    unapply: async(id) => {
        const is_local_cart = !store.state.user.logged;
        const params = is_local_cart ? new URLSearchParams({
            cart: store.state.user.localCart
        }) : '';
        // TODO: Pass cart as body with another method
        const response = await axios.delete(`${apiBaseUrl}/api/coupon/usage/${id}?${params.toString()}`, null, {
            headers: HeaderBuilder.build()
        });
        if (is_local_cart) {
            store.commit('setLocalCart', response.data);
        }
        return response.data;
    },
    query: async(code, supplier_id) => {
        const response = await axios.get(`${apiBaseUrl}/api/coupon/${code}/${supplier_id}`, {
            headers: HeaderBuilder.build()
        });
        return response.data;
    },
};

export default Coupon;